<template>
    <!-- form -->
    <form class="validate-form" @submit.prevent="updateAccountMainDetails">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Main details for {{ clientName }}</h4>

        </template>
        <template #default>
                <div class="row" >

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.slogan.msg?'text-danger':''" for="orgSlogan">
                                Organization Slogan*
                                <span v-if="fv.slogan.msg" > | {{fv.slogan.msg}}</span>
                            </label>
                            <input type="text" id="orgSlogan" class="form-control " @keyup="validate.validateFormData(fv,'slogan',true)"
                                    placeholder="Organization Slogan" name="slogan" v-model.trim="fv.slogan.val"
                            />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.orgSize.msg?'text-danger':''" for="orgSize">
                                Organization Size
                                <span v-if="fv.orgSize.msg" > | {{fv.orgSize.msg}}</span>
                            </label>
                            <select class="form-select" @change="validate.validateFormData(fv,'orgSize',true)"
                                    id="orgSize" v-model.trim="fv.orgSize.val">
                                <option value="" > Select </option>
                                <option v-for="orgSize in orgSizeOptions" :value="orgSize" :key="orgSize"
                                        >
                                    {{orgSize}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 mb-1 mt-1">
                        <label class="form-label"  :class="fv.orgInterest.msg?'text-danger':''" for="orgInterest">
                            Organization areas of interest *
                            <span v-if="fv.orgInterest.msg" > | {{fv.orgInterest.msg}}</span>
                        </label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="orgInterest" placeholder="Add skills"
                                aria-describedby="button-addon2" v-model.trim="newInterest"
                            />
                            <button
                                class="btn btn-outline-primary cursor-pointer"
                                id="button-addon2" type="button" @click="addOrgInterest">
                                <i class="bi bi-plus bs-icon-btn" ></i>
                            </button>
                        </div>
                        <div class="mb-1">
                            <base-badge
                                class="cursor-pointer mt-1"
                                v-for="interest in fv.orgInterest.val"
                                :key="interest"
                                badgeColor="secondary"
                                @click="deleteOrgInterest(interest)"
                             >
                                {{interest}}
                                <span class='text-warning'> x </span>
                            </base-badge>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.orgBio.msg?'text-danger':''" for="orgBio">
                                Organization overview*
                                <span v-if="fv.orgBio.msg" > | {{fv.orgBio.msg}}</span>
                            </label>
                            <textarea  @keyup="validate.validateFormData(fv,'orgBio',true)"
                                class="form-control" id="orgBio" rows="4" v-model.trim="fv.orgBio.val"
                                placeholder="Your Bio data here...">
                            </textarea>
                        </div>
                    </div>

                    <div class="col-12" v-if="fv.reqFeedback.msg">

                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>





                    <div class="col-12" v-if="fv.showSubmit.val">
                        <base-button btnColor="primary" >Save information</base-button>
                    </div>
                    <base-spinner  v-else></base-spinner>
                </div>


        </template>
    </base-card-plain>
    <!--Core job detail -->



    </form>
    <!--/ form -->
</template>

<script>
import { ref,reactive,inject } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation';

export default{

    setup(){

        // get the global vuex store values
        const store = useStore();

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');

        // set the validators function
        const validate = validators();

        // get the authentication status from store
        const authStatus = store.getters["auth/getAuthAuthentication"];

        // get the credentials data from store
        const credentials = store.getters["auth/getAuthCredentials"];
        const clientName = credentials.accountName;

        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldAccountData = ref(false);
        oldAccountData.value = store.getters["account/getAccountData"];

        // check if the client profile has been created
        const profileStatus = authStatus.isProfileCreated ? true : false;


        // size options for the orgSizedropdown
        const orgSizeOptions = ["1 - 10","11 - 100","101 - 1000","more than 1000"];



        const orgInterest = ref(oldAccountData.value.areas_of_interest?JSON.parse(oldAccountData.value.areas_of_interest):[])



        // form values to be used in this form
        const fv = reactive({
            slogan : {status:false, val:oldAccountData.value.slogan, msg:false, validate:["safeSpaceInput",
                                                                   "required",
                                                                   {option:"textMin",val:3},
                                                                   {option:"textMax",val:100}
                                                                  ]},
            orgSize : {status:false, val:oldAccountData.value.size, msg:false, validate:["required"]},
            orgInterest : {status:false, val:orgInterest.value, msg:false, validate:["requiredArray"]},
            orgBio : {status:false, val:oldAccountData.value.bio, msg:false, validate:["required",
                                                                   {option:"textMin",val:30},
                                                                   {option:"textMax",val:150}
                                                                  ]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });


        // remove org interest option
        function deleteOrgInterest(interest){
            let newInterest = fv.orgInterest.val;
            fv.orgInterest.val = newInterest.filter(item => item !== interest);
            validate.validateFormData(fv,"orgInterest",true);
            validate.checkInputValidity(fv);
        }


        // for adding new organization interest
        const newInterest =  ref("");
        function addOrgInterest()
        {
            let addInterest = Array.isArray(fv.orgInterest.val)?fv.orgInterest.val:[];

            if(validate.isSafeSpaceInput(newInterest.value)){
                addInterest.push(newInterest.value);
                fv.orgInterest.val = addInterest;
                newInterest.value="";
                validate.validateFormData(fv,"orgInterest",true);
                validate.checkInputValidity(fv);
            }else{
                fv.orgInterest.msg = "only alphabets and numbers are allowed";
            }

        }



        // update account main details using async
        async function updateAccountMainDetails(){

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_client_id", credentials.accountId);
            formData.append("fv_slogan", fv.slogan.val);
            formData.append("fv_orgSize", fv.orgSize.val);
            formData.append("fv_orgInterest", fv.orgInterest.val.length > 0 ?JSON.stringify(fv.orgInterest.val):"");
            formData.append("fv_orgBio", fv.orgBio.val);

            // post the data using axios
            try{

                const response = await axios.put(
                    coreUrl.backendUrl+'update_organization_main_details',
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;

                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";
                fv.showSubmit.status = false;

                if(!profileStatus){
                    const authAuthentication = {isProfileCreated : true}
                    store.dispatch("auth/executeSetAuthAuthentication",authAuthentication);
                }

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }

         return {
             fv,
             validate,
             clientName,
             orgSizeOptions,
             newInterest,
             addOrgInterest,
             deleteOrgInterest,
             updateAccountMainDetails
         }

    }
}


</script>
