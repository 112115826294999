<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-start mb-0">Account Details</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Edit account details</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <!-- account setting page -->
                <section id="page-account-settings">
                    <div class="row" v-if="existingData">
                        <!-- left menu section -->
                        <div class="col-md-3 mb-2 mb-md-0">
                            <ul class="nav nav-pills flex-column nav-left">
                                <!-- general -->
                                <li class="nav-item">
                                    <a class="nav-link active" id="account-pill-general" data-bs-toggle="pill" href="#account-vertical-general" aria-expanded="true">
                                        <i class="bi bi-bank2 bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Main details</span>
                                    </a>
                                </li>
                                <!-- social -->
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-social" data-bs-toggle="pill" href="#account-vertical-social" aria-expanded="false">
                                        <i class="bi bi-headset
                                         bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Contact details</span>
                                    </a>
                                </li>
                                <!-- information -->
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-info" data-bs-toggle="pill" href="#account-vertical-info" aria-expanded="false">
                                        <i class="bi bi-pin-map-fill bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Location details</span>
                                    </a>
                                </li>

                                <!-- information -->
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-info" data-bs-toggle="pill" href="#account-vertical-benefit" aria-expanded="false">
                                        <i class="bi bi-emoji-sunglasses bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Benefit details</span>
                                    </a>
                                </li>


                            </ul>
                        </div>
                        <!--/ left menu section -->

                        <!-- right content section -->
                        <div class="col-md-9">
                            <div class="card">
                                <div class="card-body">
                                    <div class="tab-content">
                                        <!-- general tab -->
                                        <div role="tabpanel" class="tab-pane active" id="account-vertical-general" aria-labelledby="account-pill-general" aria-expanded="true">
                                            <create-account-main-details v-if="existingData">
                                            </create-account-main-details>
                                        </div>
                                        <!--/ general tab -->

                                        <!-- Contact -->
                                        <div class="tab-pane fade" id="account-vertical-social" role="tabpanel" aria-labelledby="account-pill-social" aria-expanded="false">
                                            <create-account-contact-details  v-if="existingData">
                                            </create-account-contact-details>
                                        </div>
                                        <!--/ Contact -->

                                        <!-- location -->
                                        <div class="tab-pane fade" id="account-vertical-info" role="tabpanel" aria-labelledby="account-pill-info" aria-expanded="false">
                                            <create-account-location-details>
                                            </create-account-location-details>
                                        </div>
                                        <!--/ location -->


                                        <!-- location -->
                                        <div class="tab-pane fade" id="account-vertical-benefit" role="tabpanel" aria-labelledby="account-pill-info" aria-expanded="false">
                                            <create-account-benefit-details>
                                            </create-account-benefit-details>
                                        </div>
                                        <!--/ location -->


                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ right content section -->
                    </div>

                    <base-card-response class="mt-1" cardType="secondary" v-else>

                        <template #header></template>
                        <template #default>
                            <p>
                                {{reqError}}
                            </p>
                        </template>

                    </base-card-response>
                </section>
                <!-- / account setting page -->

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { ref,inject } from 'vue';
import { useStore } from 'vuex';

import CreateAccountMainDetails from '@/components/forms/account/CreateAccountMainDetails.vue';
import CreateAccountContactDetails from '@/components/forms/account/CreateAccountContactDetails.vue';
import CreateAccountLocationDetails from '@/components/forms/account/CreateAccountLocationDetails.vue';
import CreateAccountBenefitDetails from '@/components/forms/account/CreateAccountBenefitDetails.vue';

export default{
    components: {
        CreateAccountMainDetails,
        CreateAccountContactDetails,
        CreateAccountLocationDetails,
        CreateAccountBenefitDetails
    },
    setup(){

        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');

        // get the credentials data from store
        // const credentials = store.getters["auth/getAuthCredentials"];


        const existingData  = ref(false);
        const reqError  = ref("Loading data ....");


            // use axios to get the data
        axios.get(
                coreUrl.backendUrl+'get_org_profile_data',
                {withCredentials: true}).then((response) =>{

                const result = response.data.data;

                // use the dispatch function to call an action that calls a mutation
                // to set the data of for the job ad
                store.dispatch("account/executeSetAccountData",result);
                existingData.value = store.getters["account/getAccountData"];


        }).catch((error)=>{
                    reqError.value = error.response.data.message.req_msg?error.response.data.message.req_msg:"Account does not exist";
                    existingData.value = false;
        })





        return {
            reqError,
            existingData
        }

    }
}


</script>

<style scoped>
.bs-icon-small{
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
}

.bs-icon-tiny{
    height: 0.5rem;
    width: 0.5rem;
    font-size: 0.5rem;
    margin-right: 1.1rem;
    flex-shrink: 0;}


</style>
