<template>
    <!-- form -->
    <form class="validate-form" @submit.prevent="updateAccountSocialDetails">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Contact details</h4>

        </template>
        <template #default>
                <div class="row"  >

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.phoneNo.msg?'text-danger':''" for="phoneNo">
                                Phone number*
                                <span v-if="fv.phoneNo.msg" > | {{fv.phoneNo.msg}}</span>
                            </label>
                            <input type="text" class="form-control" id="phoneNo"  @keyup="validate.validateFormData(fv,'phoneNo',true)"
                                   placeholder="Enter phone number" v-model.trim="fv.phoneNo.val" name="dob" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.website.msg?'text-danger':''" for="website">
                                Website
                                <span v-if="fv.website.msg" > | {{fv.website.msg}}</span>
                            </label>
                            <div class="input-group input-group-merge">
                             <span class="input-group-text" style="border-right:0px">http://</span>
                                <input type="text" class="form-control" id="phoneNo"
                                       @keyup="validateLink(fv, 'website')"
                                   placeholder="Enter website" v-model.trim="fv.website.val" name="website" />
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="newMedia.media.msg?'text-danger':''" for="newMedia">
                                Social media
                                <span v-if="newMedia.media.msg" > | {{newMedia.media.msg}}</span>
                            </label>
                            <select  class="form-select" id="newMedia"
                                     v-model.trim="newMedia.media.val"
                                     @change="validate.validateFormData(newMedia,'media',false)"
                            >
                                <option value="">Select social media type</option>
                                <option v-for="social in activeSocialOptions" :key="social"  >{{social}}</option>
                            </select>
                        </div>
                    </div>


                    <div class="col-12 col-md-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label"  :class="newMedia.link.msg?'text-danger':''" for="newLink"  v-if="newMedia.link.msg">
                                Social media link* <span v-if="newMedia.link.msg" >  | {{newMedia.link.msg}}</span>
                            </label>
                            <div class="input-group input-group-merge">
                                <span class="input-group-text" style="border-right:0px">http://</span>
                                <input type="text" class="form-control" v-model.trim="newMedia.link.val"
                                       @keyup="validateLink(newMedia,'link')"
                                       id="newLink"
                                       placeholder=" Social media link e.g www.example.com"
                                 />
                                <button
                                    @click="addNewSocialMedia" class="btn btn-outline-primary"
                                    id="button-addon2" type="button"
                                    >
                                    <i class="bi bi-plus bs-icon-btn" ></i>
                                </button>
                            </div>
                        </div>
                        <div class="mt-1 mb-1">
                            <base-badge v-for="social in fv.socialDetails.val" :key="social.media"
                                        :class="social.media+'-bg'" @click="removeSocialMedia(social.media)">
                                <i :class="'bi bi-'+social.media" ></i>
                                    <span class="text-white" style="margin-left:3px">
                                        {{ social.media }}
                                    </span>
                                <span class='txt-dirty-white'> x </span>
                            </base-badge>
                        </div>
                    </div>

                    <div class="col-12" v-if="fv.reqFeedback.msg">

                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>


                    <div class="col-12" v-if="fv.showSubmit.val">
                        <base-button btnColor="primary" >Save information</base-button>
                    </div>
                    <base-spinner  v-else></base-spinner>

                </div>
        </template>
    </base-card-plain>
    <!--Core job detail -->



    </form>
    <!--/ form -->
</template>

<script>
import { ref,reactive,inject,computed } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation';


export default{


    setup(){
        // get the axios object
        const axios = inject('axios');  // inject axios

        // get the global vuex store values
        const store = useStore();

        // get the credentials data from store
        const credentials = store.getters["auth/getAuthCredentials"];

        //get the urls
        const coreUrl = inject('coreUrl');

        // set the validators function
        const validate = validators();


        const socialOptions = ref(["twitter","facebook","linkedin","instagram","pinterest"]);

        const activeSocialOptions = computed(function(){

            let currSocialOptions = socialOptions.value;

            for(let i=0; i < fv["socialDetails"]["val"].length; i++){
                currSocialOptions = currSocialOptions.filter(item => item !== fv["socialDetails"]["val"][i]["media"]);
            }

            return currSocialOptions;

        });


        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldAccountData = ref(false);
        oldAccountData.value = store.getters["account/getAccountData"];

        const oldSocialDetails = ref(oldAccountData.value.social_details?JSON.parse(oldAccountData.value.social_details):[])


        const fv = reactive({
            phoneNo : {status:true, val:oldAccountData.value.phone_no, msg:false, validate:["numeric","required"]},
            website : {status:true, val:oldAccountData.value.website, msg:false, validate:["website"]},
            socialDetails : {status:false, val:oldSocialDetails, msg:false, validate:["requiredArray"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        const newMedia = reactive({
            media : {status:false, val:"", msg:false, validate:["required"]},
            link : {status:false, val:"", msg:false, validate:["required","website"]}
        });

        function validateLink(data, link){

            data[link]['val']=data[link]['val'].replace(/https:\/\//g,"")
            data[link]['val']=data[link]['val'].replace(/http:\/\//g,"")
            validate.validateFormData(data,link,false)
        }


        function addNewSocialMedia(){

            if(newMedia.media.status && newMedia.link.status){

                let media = newMedia.media.val;
                let link = newMedia.link.val;

                let newSocialMedia =  {media:media,link:"http://"+link}
                fv.socialDetails.val.push(newSocialMedia);


                newMedia.media.val = "";
                newMedia.link.val = "";

            }else{
                fv.reqFeedback.status = true;
                fv.reqFeedback.val = "danger";
                fv.reqFeedback.msg = "Kindly enter the appropriate media & link value";
            }

        }
        // end add new social media link

        function removeSocialMedia(socialMedia){

            let newSocialDetails = [];

            for(let i=0; i < fv["socialDetails"]["val"].length; i++){
                newSocialDetails = fv.socialDetails.val.filter(item => item.media !== socialMedia);
            }

            fv.socialDetails.val = newSocialDetails;

        }

        // update social details using async
        async function updateAccountSocialDetails(){

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_client_id", credentials.accountId);
            formData.append("fv_phoneNo", fv.phoneNo.val);
            formData.append("fv_website", fv.website.val);
            formData.append("fv_socialDetails", JSON.stringify(fv.socialDetails.val));

            // post the data using axios
            try{

                const response = await axios.put(
                    coreUrl.backendUrl+'update_organization_contact_details',
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";


                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }
        // end update account social details

        return {
            fv,
            validateLink,
            newMedia,
            validate,
            socialOptions,
            activeSocialOptions,
            addNewSocialMedia,
            removeSocialMedia,
            updateAccountSocialDetails
        }
    }
}


</script>
