<template>
    <!-- form -->
    <form class="validate-form" @submit.prevent="updateAccountLocationDetails">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Location details</h4>

        </template>
        <template #default>
                <div class="row" v-if="existingLocationData">


                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="newLocation.countryId.msg?'text-danger':''" for="countryId">
                                Country
                                <span v-if="newLocation.countryId.msg" > | {{newLocation.countryId.msg}}</span>
                            </label>
                            <select class="form-select" v-model.trim="newLocation.countryId.val" id="countryId"
                                    @change="validate.validateFormData(newLocation,'countryId',false);">
                                <option value="">Select</option>
                                <option v-for="country in countryList"
                                        :value="country.country_name"
                                        :key="country.id"
                                >
                                    {{country.country_alias}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="newLocation.cityId.msg?'text-danger':''" for="allCities">
                                City
                                <span v-if="newLocation.cityId.msg" > | {{newLocation.cityId.msg}}</span>
                            </label>
                            <select class="form-select" v-model.trim="newLocation.cityId.val" @change="validate.validateFormData(newLocation,'cityId',false)" id="allCities">
                                <option value="">Select</option>
                                <option v-for="city in activeCityList"
                                        :value="city.city_name"
                                        :key="city.id"
                                >
                                    {{city.city_alias}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="newLocation.address.msg?'text-danger':''" for="address">
                                Address(optional)
                                <span v-if="newLocation.address.msg" > | {{newLocation.address.msg}}</span>
                            </label>
                            <input v-model.trim="newLocation.address.val"
                             type="text" class="form-control " placeholder="Address"
                             @keyup="validate.validateFormData(newLocation,'address',false)"
                             id="address" name="address" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label"  :class="newLocation.locationName.msg?'text-danger':''" for="locationName">
                                Give this location a name
                                <span v-if="newLocation.locationName.msg" > | {{newLocation.locationName.msg}}</span>
                            </label>
                            <input v-model.trim="newLocation.locationName.val"
                             type="text" class="form-control " placeholder="E.g Head office, Accra office"
                             @keyup="validate.validateFormData(newLocation,'locationName',false)"
                             id="locationName" name="locationName" />
                        </div>
                    </div>

                    <div class="col-12" v-if="newLocation.reqFeedback.msg">

                        <base-list-tag :tagColor="newLocation.reqFeedback.val" >{{newLocation.reqFeedback.msg}}</base-list-tag>
                    </div>

                    <div class="col-12" v-if="newLocation.showSubmit.val">
                        <base-button btnAction="button" btnColor="primary" btnType="outline"
                                    @click="addNewLocation" >
                            Add new location
                        </base-button>
                    </div>
                    <base-spinner  v-else></base-spinner>

                </div>

                <base-card-response class="mt-1" cardType="secondary"  v-else>

                    <template #header></template>
                    <template #default>
                        <p>
                            {{reqError}}
                        </p>
                    </template>

                </base-card-response>


                <div v-if="fv.locationDetails.val.length > 0">

                    <table class="table table-bordered table-responsive mt-3" >
                        <caption class="caption-options">
                            <div class="row">
                                    <h4  :class="draftStatus?'text-warning':''">
                                        Saved location details
                                        <span class="text-warning" style="font-size:12px;float:right" v-if="draftStatus">
                                            you have unsaved changes*
                                        </span>
                                    </h4>
                            </div>
                        </caption>
                        <thead>
                            <tr>
                                <th >Name</th>
                                <th class="tiny-td-col">Country</th>
                                <th class="tiny-td-col">City</th>
                                <th>Address</th>
                                <th class="tiny-td-col">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <tr class="table-hover"
                                v-for="location in fv.locationDetails.val"
                                :key="location.name"
                                :class="location.status == 'draft'?'fw-bold text-warning':'text-primary'"
                             >
                                <td>
                                        <span> {{ location.name }} </span>

                                </td>
                                <td>
                                        {{ location.country }}
                                </td>
                                <td>
                                        {{ location.city }}
                                </td>
                                <td>
                                        {{ location.address }}
                                </td>
                                <td>
                                    <div class="dropdown">
                                        <span v-if="location.rank == 'default'">
                                            <i class="bi bi-star-fill bs-icon-tiny" ></i>
                                        </span>
                                        <span v-else>
                                            <i class="bi bi-star bs-icon-tiny" @click="setDefaultLocation(location.name)" ></i>
                                            <i class="bi bi-trash bs-icon-tiny ps-1" @click="removeDefaultLocation(location.name)" ></i>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="col-12 mt-2" v-if="fv.reqFeedback.msg">

                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>

                    <div class="col-12" v-if="fv.showSubmit.status">
                        <div class="col-12" v-if="fv.showSubmit.val">
                            <base-button btnColor="primary" >Save information</base-button>
                        </div>
                        <base-spinner  v-else></base-spinner>
                    </div>

                </div>



        </template>
    </base-card-plain>
    <!--Core job detail -->


    </form>
    <!--/ form -->
</template>

<script>
import {ref,reactive,computed,inject} from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation';

export default{
    setup(){
        // get the axios object
        const axios = inject('axios');  // inject axios

        // get the global vuex store values
        const store = useStore();

        //get the urls
        const coreUrl = inject('coreUrl');

        // set the validators function
        const validate = validators();


        //get the data for country and cities
        const countryList = ref(false);
        const cityList = ref(false);

        const reqError = ref("Loading forms...");
        const existingLocationData = ref(true);

        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldAccountData = ref(false);
        oldAccountData.value = store.getters["account/getAccountData"];

        const currLocationDetails = ref(oldAccountData.value.location_details);
        // when the vue module is created, get the current profile data, using the try catch

            // use axios to get the data
            axios.get(
                coreUrl.backendUrl+'get_location_data',
                {withCredentials: true}).then((response) =>{

                // return the data to result and update existing data variable
                const result = response.data.data;

                countryList.value = result.countryList;
                cityList.value = result.cityList;
                existingLocationData.value = true;

            }).catch((error)=>{
                let errorMsg = "Can't load location data at this time."
                reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
                existingLocationData.value = false;
            })



        // form values to be used in this form
        const fv = reactive({
            locationDetails : {status:true, val:oldAccountData.value.location_details?JSON.parse(oldAccountData.value.location_details):[], msg:false},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });


        // form values to be used in this form
        const newLocation = reactive({
            cityId : {status:false, val:"", msg:false, validate:["required"]},
            countryId : {status:false, val:"", msg:false, validate:["required"]},
            address : {status:false, val:"", msg:false, validate:["required","safeSpaceComma"]},
            locationName : {status:false, val:"", msg:false, validate:["required",
                                                                       "safeSpaceInput",
                                                                       {option:"textMin",val:5},
                                                                       {option:"textMax",val:50}]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        const draftStatus = ref(false);

        const activeCityList = computed(function(){
            let newCityList = [];

            if(cityList.value){
                for(let i =0; i < cityList["value"].length; i++){
                    if(cityList.value[i]["country_name"] === newLocation.countryId.val){
                        newCityList.push(cityList.value[i]);
                    }
                }
            }

            return newCityList;

        });



        function addNewLocation(){

            fv.reqFeedback.msg = "";
            newLocation.showSubmit.val = false;
            newLocation.reqFeedback.val = "";
            newLocation.reqFeedback.status = false;

            let city = newLocation.cityId.val;
            let country = newLocation.countryId.val;
            let address = newLocation.address.val;
            let name = newLocation.locationName.val;
            let rank =fv.locationDetails.val.length == 0 ? "default":"none";

            let existingLocation = fv.locationDetails.val.findIndex(item => item.name === name) < 0 ? true:false;

            if(existingLocation && newLocation.cityId.status && newLocation.countryId.status
                && newLocation.address.status && newLocation.locationName.status){


                let currNewLocation =  {name:name,country:country,city:city,address:address,rank:rank,status:"draft"}
                fv.locationDetails.val.push(currNewLocation);

                newLocation.showSubmit.val = true;
                newLocation.reqFeedback.val = "";
                newLocation.reqFeedback.msg = "";

                draftStatus.value = true;

                fv.showSubmit.status = true;


            }else{
                newLocation.showSubmit.val = true;
                newLocation.reqFeedback.status = true;
                newLocation.reqFeedback.val = "danger";
                newLocation.reqFeedback.msg = "Kindly enter the appropriate values correctly";
                newLocation.locationName.msg = existingLocation?"":"The location name already exists";
            }
        }
        // end add new location data


        function setDefaultLocation(location_name){

            fv.reqFeedback.msg = "";

            let currDefaultIndex = fv.locationDetails.val.findIndex(item => item.rank === "default");
            fv["locationDetails"]["val"][currDefaultIndex]["rank"] = "none";
            fv["locationDetails"]["val"][currDefaultIndex]["status"] = "draft";

            let newDefaultIndex = fv.locationDetails.val.findIndex(item => item.name === location_name);
            fv["locationDetails"]["val"][newDefaultIndex]["rank"] = "default";
            fv["locationDetails"]["val"][newDefaultIndex]["status"] = "draft";

            draftStatus.value = true;

            fv.showSubmit.status = true;
        }


        // remove default location
        function removeDefaultLocation(location_name){

            fv.reqFeedback.msg = "";

            let newLocationDetails = [];


            newLocationDetails = fv.locationDetails.val.filter(item => item.name !== location_name);

            fv.locationDetails.val = newLocationDetails;

            draftStatus.value =  currLocationDetails.value == JSON.stringify(fv.locationDetails.val) ? false : true;
            fv.showSubmit.status = true;

        }


        // update social details using async
        async function updateAccountLocationDetails(){

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();

            let locationDetails = JSON.stringify(fv.locationDetails.val);
            let cleanLocationDetails = locationDetails.replace(/draft/g,'active');

            formData.append("fv_locationDetails", cleanLocationDetails);

            // post the data using axios
            try{

                const response = await axios.put(
                    coreUrl.backendUrl+'create_organization_location_details',
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";

                fv.locationDetails.val = JSON.parse(cleanLocationDetails);
                currLocationDetails.value = cleanLocationDetails;

                draftStatus.value = false;

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;
                fv.showSubmit.status = false;


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }


        return {
            fv,
            newLocation,
            addNewLocation,
            setDefaultLocation,
            removeDefaultLocation,
            draftStatus,
            existingLocationData,
            reqError,
            validate,
            countryList,
            activeCityList,
            updateAccountLocationDetails
        }


    } // close setup() tag
}


</script>


<style scoped>

.caption-options{
    caption-side:top;
}

</style>
