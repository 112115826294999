<template>
    <!-- form -->
    <form class="validate-form" @submit.prevent="updateAccountBenefitDetails">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Benefit details</h4>

        </template>
        <template #default>
                <div class="row"  >

                    <div class="col-12 col-md-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="newBenefit.type.msg?'text-danger':''" for="newBenefit">
                                Benefit type
                                <span v-if="newBenefit.type.msg" > | {{newBenefit.type.msg}}</span>

                            </label>
                            <i class="bi bi-info-circle-fill mr-3 pr-3" style="float:right"> </i>
                            <select  class="form-select" id="newBenefit"
                                     v-model.trim="newBenefit.type.val"
                                     @change="validate.validateFormData(newBenefit,'type',false)"
                            >
                                <option value="">Select benefit type</option>
                                <option v-for="(benefit,index) in benefitTypes"
                                        :key="index" :value="benefit.value"  >{{ benefit.alias }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-md-12 col-sm-12" v-if="newBenefit.type.val">
                        <div class="mb-1">
                            <label class="form-label" :class="newBenefit.benefit.msg?'text-danger':''" for="newBenefit">
                                Benefit Options
                                <span v-if="newBenefit.benefit.msg" > | {{newBenefit.benefit.msg}}</span>

                            </label>
                            <i class="bi bi-info-circle-fill mr-3 pr-3" style="float:right"> </i>
                            <select  class="form-select" id="newBenefit"
                                     v-model.trim="newBenefit.benefit.val"
                                     @change="validate.validateFormData(newBenefit,'benefit',false)"
                            >
                                <option value="">Select benefit option</option>
                                <option v-for="(benefit,index) in activeBenefitOptions"
                                        :key="index" :value="benefit"  >{{ benefit }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="newBenefit.details.msg?'text-danger':''" for="phoneNo">
                                Additional details(optional)
                                <span v-if="newBenefit.details.msg" > | {{newBenefit.details.msg}}</span>
                            </label>
                            <i class="bi bi-info-circle-fill mr-3 pr-3" style="float:right"> </i>
                            <input type="text" class="form-control" id="phoneNo"  @keyup="validate.validateFormData(newBenefit,'details',false)"
                                   placeholder="Enter specific details e.g how man leave days"
                                   v-model.trim="newBenefit.details.val" name="details" />
                        </div>
                    </div>

                    <div class="col-12" v-if="newBenefit.reqFeedback.msg">
                        <base-list-tag :tagColor="newBenefit.reqFeedback.val" >
                            {{newBenefit.reqFeedback.msg}}
                        </base-list-tag>
                    </div>

                    <div class="col-12" v-if="fv.showSubmit.val">
                        <base-button btnColor="primary" btnType="outline" btnAction="button" @click="addNewBenefit" >
                            Add benefit
                        </base-button>
                    </div>
                    <base-spinner  v-else></base-spinner>

                    <div class="col-12 mt-5" >
                        <base-list-tag tagColor="secondary"
                                        v-for="(b, index) in fv.benefitDetails.val" :key="index" >
                                        {{ b.benefit }} ( {{ b.details }} )
                                    </base-list-tag>
                    </div>

                    <div class="col-12" v-if="fv.reqFeedback.msg">

                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>

                    <div v-if="fv.benefitDetails.val">
                        <div class="col-12" v-if="fv.showSubmit.val">
                            <base-button btnColor="primary" >Save information</base-button>
                        </div>
                        <base-spinner  v-else></base-spinner>
                    </div>

                </div>
        </template>
    </base-card-plain>
    <!--Core job detail -->



    </form>
    <!--/ form -->
</template>

<script>
import { ref,reactive,inject,computed } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation';


export default{

    name:'CreateAccountBenefitDetails',
    setup(){
        // get the axios object
        const axios = inject('axios');  // inject axios

        // get the global vuex store values
        const store = useStore();

        //get the urls
        const coreUrl = inject('coreUrl');

        // set the validators function
        const validate = validators();


        const benefitTypes = ref([
            {value:"insurance_health_care", alias:"Health Insurance"},
            {value:"work_life_balance", alias:"Work life balance"},
            {value:"financial_pension", alias:"Financial & Pension"},
            {value:"family_support", alias:"Family Support"},
            {value:"free_perks", alias:"Free perks"},
            {value:"professional_support", alias:"Professional Support"}
        ]);

        const benefitOptions = ref({
            insurance_health_care:['Health Insurance','Dental Insurance','Life Insurance'],
            work_life_balance:['Paid leave days','Paid Vacation'],
            financial_pension:['Pension plan'],
            family_support:['Paternal leave','Maternal leave'],
            free_perks:['Office Gym','Paid Gym service'],
            professional_support:['Performance evaluation', 'Paid training']
        });

        const activeBenefitOptions = computed(function(){

            let currbenefitOptions = benefitOptions.value[newBenefit.type.val];


            for(let i=0; i < fv["benefitDetails"]["val"].length; i++){

                currbenefitOptions = currbenefitOptions.filter(item => item !== fv["benefitDetails"]["val"][i]['benefit']);
            }


            return currbenefitOptions;

        });


        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldAccountData = ref(false);
        oldAccountData.value = store.getters["account/getAccountData"];

        console.log(oldAccountData.value)

        const oldBenefitDetails = ref(oldAccountData.value.benefit_details?JSON.parse(oldAccountData.value.benefit_details):[])


        const fv = reactive({
            benefitDetails : {status:false, val:oldBenefitDetails, msg:false, validate:["requiredArray"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        const newBenefit = reactive({
            type : {status:false, val:"", msg:false, validate:["required"]},
            benefit : {status:false, val:"", msg:false, validate:["required"]},
            details : {status:false, val:"", msg:false, validate:["required","safeSpaceInput"]},
            reqFeedback : {status:false, val:"", msg:false},
        });

        function validateLink(data, link){

            data[link]['val']=data[link]['val'].replace(/https:\/\//g,"")
            data[link]['val']=data[link]['val'].replace(/http:\/\//g,"")
            validate.validateFormData(data,link,false)
        }


        function addNewBenefit(){

            if(newBenefit.type.status && newBenefit.benefit.status){

                let newType = newBenefit.type.val;
                let currBenefit = newBenefit.benefit.val;
                let newDetails = newBenefit.details.val?newBenefit.details.val:"none";

                let newBenefitSet =  {type:newType,benefit:currBenefit,details:newDetails}
                fv.benefitDetails.val.push(newBenefitSet);


                // newBenefit.type.val = "";
                newBenefit.benefit.val = "";
                newBenefit.details.val = "";

            }else{
                newBenefit.reqFeedback.status = true;
                newBenefit.reqFeedback.val = "danger";
                newBenefit.reqFeedback.msg = "Kindly enter the appropriate benefit type & option value";
            }

        }
        // end add new social benefit link

        function removeBenefitDetails(benefit){

            let newBenefitDetails = [];

            for(let i=0; i < fv["benefitDetails"]["val"].length; i++){
                newBenefitDetails = fv.benefitDetails.val.filter(item => item.benefit !== benefit);
            }

            fv.benefitDetails.val = newBenefitDetails;

        }

        // update social details using async
        async function updateAccountBenefitDetails(){

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_benefitDetails", JSON.stringify(fv.benefitDetails.val));

            // post the data using axios
            try{

                const response = await axios.put(
                    coreUrl.backendUrl+'update_organization_benefit_details',
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";


                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }
        // end update account social details

        return {
            fv,
            validateLink,
            newBenefit,
            validate,
            benefitTypes,
            activeBenefitOptions,
            addNewBenefit,
            removeBenefitDetails,
            updateAccountBenefitDetails
        }
    }
}


</script>
